import React, { FC, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import {
  Container,
  DetailsCard,
  Row as Line,
} from '@/features/dashboard/reports_v2/lists/shared';
import { useReportsApi } from '@/features/dashboard/reports_v2/api';
import { VSpace } from '@/components/spacing';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { getChipColor } from '@/components/dot-label';
import { StatusChip } from '@/features/details/experiment-details-audience-preview';

export const Uplifts: FC = () => {
  const api = useReportsApi();
  const [uplifts, setUplifts] = useState<
    Array<{
      name: string;
      id: string;
      status: string;
      uplift: {
        testVersion: number;
        revViaExp: number;
        storeRev: number;
        cr: number;
        runningDays: number;
        psv: number;
        aov: number;
      };
    }>
  >([]);
  useAsyncEffect(async () => {
    const resp = await api.getUplifts();
    const map = resp?.experiences
      ?.filter(
        (e) => !!e.uplift && e.uplift.storeRev > 0 && e.uplift.revViaExp > 0,
      )
      ?.map((e) => ({
        name: e.name,
        uplift: e.uplift,
        status: e.status,
        id: e.id,
      }));
    if (map) {
      setUplifts(map || []);
    }
  }, []);
  const siteStr = `site's`;

  const crAccumulated = uplifts
    .reduce((p, c) => {
      const coefficient = c.uplift.revViaExp / c.uplift.storeRev;
      return p + (c?.uplift?.cr || 0) * coefficient;
    }, 0)
    .toFixed(2);
  const psvAccumulated = uplifts
    .reduce((p, c) => {
      const coefficient = c.uplift.revViaExp / c.uplift.storeRev;
      return p + (c?.uplift?.psv || 0) * coefficient;
    }, 0)
    .toFixed(2);
  const isOn = useFeatureBit(FeatureBit.UPLIFT_METRICS);
  if (!isOn) {
    return null;
  }
  return (
    <DetailsCard loading={api.loading} open>
      <Container>
        <>
          <Header>
            <Title>Accumulated Uplift</Title>
            <Subtitle1>
              Accumulated revenue uplift is calculated by analyzing all A/B
              tests that have reached 100% completion and assessing their actual
              impact on total revenue.
              <br />
              <Subtitle2>
                * For example, if an experiment running on mobile devices shows
                a 10% improvement, and mobile contributes 50% of the revenue,
                the real impact would <br />
                be a 5% uplift in the {siteStr} conversion rate.
              </Subtitle2>
            </Subtitle1>
            <AccumulatedResults>
              <AccumulatedResult>
                <AccResTitle>
                  Accumulated CR Uplift
                  <br /> (All-Time)
                </AccResTitle>
                <AccResValue>{crAccumulated}%</AccResValue>
              </AccumulatedResult>
              <AccumulatedResult>
                <AccResTitle>
                  Accumulated PSV Uplift
                  <br /> (All-Time)
                </AccResTitle>
                <AccResValue>{psvAccumulated}%</AccResValue>
              </AccumulatedResult>
              <AccumulatedResult>
                <AccResTitle>
                  Experiences
                  <br /> Moved to 100%
                </AccResTitle>
                <AccResValue>{uplifts?.length || ``}</AccResValue>
              </AccumulatedResult>
            </AccumulatedResults>
          </Header>
          <Line style={{ borderBottom: `none` }}>
            <ExpName />
            <ExpStats>
              <TableHeader>
                Running
                <br /> Days
              </TableHeader>
              <TableHeader>
                Accumulated <br /> CR uplift
              </TableHeader>
              <TableHeader>
                Accumulated <br />
                PSV uplift
              </TableHeader>
              <TableHeader>
                Accumulated <br />
                AOV uplift
              </TableHeader>
            </ExpStats>
          </Line>

          {uplifts?.map((x, idx) => {
            const bg = getChipColor(x?.status as any);
            const coefficient = x.uplift.revViaExp / x.uplift.storeRev;
            return (
              <Row
                key={x.name}
                bt={idx === 0}
                onClick={() => nav(`${Pages.EXPERIENCE_DETAILS}?expId=${x.id}`)}
              >
                <ExpName>
                  <StatusChip
                    style={{
                      marginRight: `1rem`,
                      width: `67px`,
                      textAlign: `center`,
                    }}
                    bg={bg}
                  >
                    {x?.status === `PAUSED` ? `paused` : `live`}
                  </StatusChip>
                  {x.name}
                </ExpName>
                <ExpStats>
                  <span>{x?.uplift?.runningDays || ``}</span>
                  <span>
                    {((x?.uplift?.cr || 0) * coefficient).toFixed(2)}%
                  </span>
                  <span>
                    {((x?.uplift?.psv || 0) * coefficient).toFixed(2)}%
                  </span>
                  <span>
                    {((x?.uplift?.aov || 0) * coefficient).toFixed(2)}%
                  </span>
                </ExpStats>
              </Row>
            );
          })}
          <VSpace value={2} />
        </>
      </Container>
    </DetailsCard>
  );
};

export const ExpName = styled.div`
  font-family: Inter, serif;
  font-weight: 400;
  color: #42494e;
  font-size: 1.4rem;
  width: 45%;
  text-align: start;
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const ExpStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter, serif;
  color: #42494e;
  font-size: 1.4rem;
  width: 50%;

  span {
    user-select: text;
    text-align: end;
  }
`;
const TableHeader = styled.span`
  font-family: Inter, serif;
  color: #a3acb5;
  text-align: start !important;
  font-weight: 600;
`;

const Header = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

const AccumulatedResult = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AccResTitle = styled.span`
  text-align: start;
  font-family: Inter, serif;
  font-weight: 600;
  color: #a3acb5;
  font-size: 1.6rem;
`;
const AccResValue = styled.span`
  margin-top: 2rem;
  color: black;
  text-align: start;
  font-weight: 600;
  font-family: Inter, serif;
  font-size: 3rem;
`;

const Subtitle1 = styled.p`
  text-align: start;
  font-family: Inter, serif;
  font-weight: 400;
  color: black;
  font-size: 1.6rem;
  line-height: 3.8rem;
`;
const Subtitle2 = styled.span`
  color: #798794;
`;
const Title = styled.p`
  font-family: Inter, serif;
  font-weight: 700;
  color: black;
  font-size: 2rem;
`;

const AccumulatedResults = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4rem;
  margin: 2rem auto;
  padding: 2% 8%;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
`;
export const Row = styled.div`
  border-top: ${(p: any) => (p?.bt ? `2.8px solid #ebebeb` : `none`)};
  border-bottom: 2.8px solid #ebebeb;
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
